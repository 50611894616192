<template>
    <v-container
        tag="section"
    >
        <v-row
            justify="center"
            v-if="packageAll.paytime >= dateNow"
        >        
            <v-col
                cols="12"
                lg="9"
                xl="7"
                class="block-media-lk"
            >
                <base-material-card
                    color="warning"
                    class="px-sm-8 px-md-16 px-2 py-3"
                >
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                          RoboMoney
                        </div>
                    </template>

                    <v-card-text
                        class="mt-10 mb-10 card-media-lk"
                    >
                      <p class="mb-7">
                        RoboMoney -  Уникальный Робот по автоматизации сетевого бизнеса на базе Искусственного Интеллекта.                        
                      </p>
                      <p class="mb-5">
                        Цель - увеличение количества кандидатов и регистраций на бизнес. Быстрый выход на доход.
                      </p>
                      <p class="mb-5">
                        Повышает уровень интереса к предложению.
                      </p>
                      <p class="mb-5">
                        Поиск кандидатов <br>
                        Первое касание и бизнес предложение <br>
                        Вывод на созвон и взятие номера телефона
                      </p>
                      <p class="mb-5">
                        Используй технологии будущего для увеличения дохода уже сегодня!!!
                      </p>

                      <v-divider class="mt-10"></v-divider>

                      <p class="mt-10">
                        <v-icon class="mr-2" color="blue">mdi-arrow-right-bold-outline</v-icon>
                        <router-link
                            color="success"
                            :to="{name: 'RoboMoneyMlm', query: { partner: $store.state.user.profile.lr_number }}"
                        >
                          RoboMoney для сетевиков
                        </router-link>
                      </p>
                      <p class="mb-n2">
                          <v-icon class="mr-2" color="blue">mdi-arrow-down-bold-outline</v-icon>
                          <strong>Ссылка на RoboMoney для сетевиков</strong>:
                          <v-text-field
                              :value="`https://newlvl.net/robo-money-mlm/?partner=${$store.state.user.profile.lr_number}`"
                              readonly
                              id="copyRefLinkRoboMlm"
                          ></v-text-field>
                      </p>
                      <v-btn
                          color="gray"
                          @click="copyRefLinkRoboMlm"
                          class="mb-5"
                      >
                          Скопировать ссылку
                      </v-btn>

                      <v-divider class="mt-7"/>

                      <p class="mt-10">
                        <v-icon class="mr-2" color="blue">mdi-arrow-right-bold-outline</v-icon>
                        <router-link
                            color="success"
                            :to="{name: 'RoboMoney', query: { partner: $store.state.user.profile.lr_number }}"
                        >
                          RoboMoney
                        </router-link>
                      </p>
                      <p class="mb-n2">
                          <v-icon class="mr-2" color="blue">mdi-arrow-down-bold-outline</v-icon>
                          <strong>Ссылка на RoboMoney</strong>:
                          <v-text-field
                              :value="`https://newlvl.net/robo-money/?partner=${$store.state.user.profile.lr_number}`"
                              readonly
                              id="copyRefLinkRobo"
                          ></v-text-field>
                      </p>
                      <v-btn
                          color="gray"
                          @click="copyRefLinkRobo"
                          class="mb-5"
                      >
                          Скопировать ссылку
                      </v-btn>
                      <v-divider class="mt-7"/>

                      <p class="mt-10">
                        <v-icon class="mr-2" color="blue">mdi-arrow-right-bold-outline</v-icon>
                        <router-link
                            color="success"
                            :to="{name: 'RoboMoneyShort', query: { partner: $store.state.user.profile.lr_number }}"
                        >
                          RoboMoney Ерматова
                        </router-link>
                      </p>
                      <p class="mb-n2">
                          <v-icon class="mr-2" color="blue">mdi-arrow-down-bold-outline</v-icon>
                          <strong>Ссылка на RoboMoney Ерматова</strong>:
                          <v-text-field
                              :value="`https://newlvl.net/robo-money-short/?partner=${$store.state.user.profile.lr_number}`"
                              readonly
                              id="copyRefLinkRoboShort"
                          ></v-text-field>
                      </p>
                      <v-btn
                          color="gray"
                          @click="copyRefLinkRoboShort"
                          class="mb-5"
                      >
                          Скопировать ссылку
                      </v-btn>
                      <v-divider class="mt-7"/>
                      <p class="mb-3">
                        <a href="https://teletype.in/@robomoney_startrack/z_sLh6SmNcC" target="_blank">Инструкция по использованию</a>
                      </p>
                      <p class="mb-3">
                        <a href="https://teletype.in/@robomoney_startrack/ouctZ02SGz4" target="_blank">Инструкция по подключению</a>
                      </p>
                      <p class="mb-3">
                        <a href="https://teletype.in/@robomoney_startrack/Fa0EqKxmbRO" target="_blank">Примеры постов и статуса</a>
                      </p>
                      <p class="mb-3">
                        <a href="https://teletype.in/@robomoney_startrack/eDd7lI2UbSZ" target="_blank">Запуск по команде</a>
                      </p>
                    </v-card-text>
                </base-material-card>
            </v-col>
        </v-row>
        <v-row
            justify="center"
            v-if="!packageAll.paytime || packageAll.paytime < dateNow"
        >
            <v-col
                cols="12"
                lg="9"
                xl="7"
            >
                <base-material-card
                    color="red"
                    class="px-md-16 px-5 py-3"
                >
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Ошибка доступа
                        </div>

                        <div class="subtitle-1 font-weight-light">

                        </div>
                    </template>

                    <v-card-text
                        class="mt-10"
                    >
                        <p>
                            <v-icon
                                color="red"
                                class="mr-2"
                            >
                                mdi-alert-circle-outline
                            </v-icon>
                            У вас нет доступа к просмотру данной страницы.</p>
                        <p>
                            <v-icon
                                class="mr-2"
                                color="blue"
                            >
                                mdi-arrow-right-bold-outline
                            </v-icon>
                            Для получения доступа к инструментам обратитесь к куратору:
                            <router-link
                                color="success"
                                :to="{name: 'PayInstruments'}"
                            >
                                Как получить доступ к инструментам
                            </router-link>
                        </p>
                    </v-card-text>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "ReqHealthLearn",
    data: () => ({
        dateNow: new Date().toISOString().substr(0, 10),
        dialog: true,
        paytimeUser: ''
    }),
    computed: {
        ...mapGetters({
          packageAll: 'user/getProfileAll'
        }),
        datePay() {
            let D = new Date()
            D.setDate(D.getDate() + 7)
            return D.toISOString().substr(0, 10)
        }
    },
    created () {
        this.initializeUserPackage()
    },
    methods: {
        ...mapActions({
            loadPackageAll: 'user/loadProfileAll',
            loadProfilePackage: 'user/loadProfilePackage',
        }),
        copyRefLinkRoboMlm () {
            let copyRefLinkRoboMlm = document.getElementById('copyRefLinkRoboMlm')

            copyRefLinkRoboMlm.select()

            document.execCommand('copy')
        },
        copyRefLinkRobo () {
            let copyRefLinkRobo = document.getElementById('copyRefLinkRobo')

            copyRefLinkRobo.select()

            document.execCommand('copy')
        },
        copyRefLinkRoboShort () {
            let copyRefLinkRoboShort = document.getElementById('copyRefLinkRoboShort')

            copyRefLinkRoboShort.select()

            document.execCommand('copy')
        },
        initializeUserPackage() {
            this.loadProfilePackage()
                .then((response) => {
                    this.loadingPackage = false
                    this.paytimeUser = response.data.paytime
                })
        },
    },
    mounted() {
        this.loadPackageAll()
        this.loadProfilePackage()
    }
}
</script>

<style lang="sass">
.p-title
  text-transform: uppercase
  font-weight: bold

.exp-panel-title .v-expansion-panel-header
    font-size: 14px

.exp-panel-title .v-expansion-panel--active
    border: 1px solid #69db69

.img-secret
  max-width: 300px
  margin-right: 10px

.img-blic
  max-width: 300px
  margin: 10px
  border: 1px solid #ccc
  border-radius: 10px

@media(max-width: 600px)
    .block-media-lk
        padding: 0

    .card-media-lk
        padding: 0

</style>
